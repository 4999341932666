<template>
  <div v-if="!load">
    <div class="text-center text-danger screen-center">
      <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
      <strong>Cargando...</strong>
    </div>
  </div>
  <div v-else>
    <div class="breadcrumb">
      <h1>Nuevo Medico</h1>
    </div>
    <div class="row mb-2">
      <div class="col-sm-12">
        <div class="card text-left">
          <!-- <form @submit.prevent="guardarMedico" enctype="multipart/form-data" > -->
            <div class="card-header">
              <div class="col-sm-5">
                <button
                  type="submit"
                  class="btn bg-custom-green mr-2 rounded"
                  @click="guardarMedico"
                >Guardar
                </button>
                <router-link :to="{ name: 'medico-index' }">
                  <button class="btn btn-white border border-light rounded">
                    Regresar
                  </button>
                </router-link>
              </div>
            </div>
            <div class="card-body">
              <div class="row mb-2">
                <div class="col-sm-12 py-3 px-2">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="row mb-2">
                        <div class="col-sm-3 pr-0 text-right">
                          <label class="col-form-label">Nombre y Apellido:</label>
                        </div>
                        <div class="col-sm-7">
                          <input type="text"
                            class="form-control"
                            v-model="nombre"
                            autocomplete="off"
                          >
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-3 pr-0 text-right">
                          <label class="col-form-label">Email:</label>
                        </div>
                        <div class="col-sm-7">
                          <input
                            type="email"
                            class="form-control"
                            v-model="email"
                            autocomplete="off"
                          >
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-3 pr-0 text-right">
                          <label class="col-form-label">Direccion:</label>
                        </div>
                        <div class="col-sm-7">
                          <input
                            type="text"
                            class="form-control"
                            v-model="direccion"
                            autocomplete="off"
                          >
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-3 pr-0 text-right">
                          <label class="col-form-label">Especialidad:</label>
                        </div>
                        <div class="col-sm-7">
                          <vue-tags-input
                            v-model="especialidadInput"
                            :tags="especialidadesSelected"
                            class="tag-custom text-15"
                            :autocomplete-items="filteredItems"
                            @tags-changed="validarEspecialidad"
                            placeholder="Selecciona las Especialidades"
                          />
                        </div>
                      </div>
                     <div class="row mb-2" v-if="hasPermissionMedicoTercero">
                        <div class="col-sm-4 pr-0">
                          <template v-for="(modalidad, index) in modalidades" >
                            <button
                              :key="index"
                              class="btn btn-block text-center"
                              :class="colorBtnModalidadElectronica()"
                              v-if="tipoFactura == modalidad.id"
                              @click="cambiarModalidad(index + 1)"
                            >
                                {{ modalidad.nombre }}
                            </button>
                          </template>
                        </div>
                        <div class="col-sm-3 pr-0">
                          <button
                            class="btn btn-block"
                            :class="tieneModalidadSinFactura? 'btn-success': 'btn-secondary'"
                            @click="tieneModalidadSinFactura = !tieneModalidadSinFactura"
                          >
                            Sin Factura
                          </button>
                        </div>
                        <div class="col-sm-3">
                          <button
                            class="btn btn-block"
                            :class="tieneModalidadFacturaManual? 'btn-success': 'btn-secondary'"
                            @click="tieneModalidadFacturaManual = !tieneModalidadFacturaManual"
                          >
                            Factura Manual
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="row mb-2">
                        <div class="col-sm-3 text-right">
                          <label class="col-form-label">Fecha de Nacimiento:</label>
                        </div>
                        <div class="col-sm-4">
                          <date-picker
                            class="col-sm-10 px-0"
                            v-model="fechaNacimiento"
                            lang="es"
                            format="DD/MM/YYYY"
                            type="date"
                            value-type="date"
                            @input="getAge(fechaNacimiento)"
                          ></date-picker>
                        </div>
                        <div class="col-sm-2 pl-0">
                          <label class="col-form-label">
                            {{edadActual}}
                          </label>
                        </div>
                        <button
                          v-if="!isDisableTratamiento"
                          type="button"
                          class="col-sm-2 btn"
                          :class="{'btn-warning': !isAtencion, 'btn-success': isAtencion}"
                          v-b-modal.modal-atencion
                        >Horario
                        </button>
                        <b-modal size="lg" id="modal-atencion" title="Dias de Atencion" hide-footer>
                          <!-- Turnos -->
                          <div class="row">
                            <div class="col-sm-6">
                              <div class="col-sm-12 text-center"><b>Primer Turno</b></div>
                              <!-- Ingreso y Salida -->
                              <div class="row mb-1">
                                <div class="col-sm-1"></div>
                                <div class="col-sm-2"></div>
                                <div class="col-sm-3 text-center"><b>Ingreso</b></div>
                                <div class="col-sm-3 text-center"><b>Salida</b></div>
                                <div class="col-sm-3"></div>
                              </div>
                              <!-- Fin -->
                              <!-- Lunes -->
                              <div class="row mb-1">
                                <div class="col-sm-1 text-right pr-0">
                                  <div class="form-check pt-1">
                                    <input class="form-check-input" type="checkbox"
                                    id="checkLunesPrimerTurno"
                                    v-model="diasAtencion.primerTurno.lunes.estado">
                                  </div>
                                </div>
                                <div class="col-sm-2">
                                  <label
                                    class="col-form-label"
                                    for="checkLunesPrimerTurno"
                                  >Lunes:
                                  </label>
                                </div>
                                <div class="col-sm-3 pr-0">
                                  <input
                                    type="time"
                                    class="form-control pl-1 pr-2"
                                    v-model="diasAtencion.primerTurno.lunes.ingreso"
                                    :disabled="!diasAtencion.primerTurno.lunes.estado"
                                    @change="restarHora(diasAtencion.primerTurno.lunes,
                                    'primerTurno')"
                                  >
                                </div>
                                <div class="col-sm-3 pl-1">
                                  <input
                                    type="time"
                                    class="form-control pl-1 pr-2"
                                    v-model="diasAtencion.primerTurno.lunes.salida"
                                    :disabled="!diasAtencion.primerTurno.lunes.estado"
                                    @change="restarHora(diasAtencion.primerTurno.lunes,
                                    'primerTurno')"
                                  >
                                </div>
                                <div class="col-sm-3 pl-0">
                                  {{diasAtencion.primerTurno.lunes.totalTiempo}}
                                </div>
                              </div>
                              <!-- Martes -->
                              <div class="row mb-1">
                                <div class="col-sm-1 text-right pr-0">
                                  <div class="form-check pt-1">
                                    <input class="form-check-input" type="checkbox"
                                    id="checkMartesPrimerTurno"
                                    v-model="diasAtencion.primerTurno.martes.estado">
                                  </div>
                                </div>
                                <div class="col-sm-2">
                                  <label
                                    class="col-form-label"
                                    for="checkMartesPrimerTurno"
                                  >Martes:
                                  </label>
                                </div>
                                <div class="col-sm-3 pr-0">
                                <input type="time" class="form-control pl-1 pr-2"
                                  v-model="diasAtencion.primerTurno.martes.ingreso"
                                  :disabled="!diasAtencion.primerTurno.martes.estado"
                                  @change="restarHora(diasAtencion.primerTurno.martes,
                                  'primerTurno')">
                                </div>
                                <div class="col-sm-3 pl-1">
                                  <input type="time" class="form-control pl-1 pr-2"
                                  v-model="diasAtencion.primerTurno.martes.salida"
                                  :disabled="!diasAtencion.primerTurno.martes.estado"
                                  @change="restarHora(diasAtencion.primerTurno.martes,
                                  'primerTurno')">
                                </div>
                                <div class="col-sm-3 pl-0">
                                  {{diasAtencion.primerTurno.martes.totalTiempo}}
                                </div>
                              </div>
                              <!-- Miercoles -->
                              <div class="row mb-1">
                                <div class="col-sm-1 text-right pr-0">
                                  <div class="form-check pt-1">
                                    <input class="form-check-input" type="checkbox"
                                    id="checkMiercolesPrimerTurno"
                                    v-model="diasAtencion.primerTurno.miercoles.estado">
                                  </div>
                                </div>
                                <div class="col-sm-2">
                                  <label class="col-form-label" for="checkMiercolesPrimerTurno">
                                    Miercoles:
                                  </label>
                                </div>
                                <div class="col-sm-3 pr-0">
                                <input type="time" class="form-control pl-1 pr-2"
                                  v-model="diasAtencion.primerTurno.miercoles.ingreso"
                                  :disabled="!diasAtencion.primerTurno.miercoles.estado"
                                  @change="restarHora(diasAtencion.primerTurno.miercoles,
                                  'primerTurno')">
                                </div>
                                <div class="col-sm-3 pl-1">
                                  <input type="time" class="form-control pl-1 pr-2"
                                  v-model="diasAtencion.primerTurno.miercoles.salida"
                                  :disabled="!diasAtencion.primerTurno.miercoles.estado"
                                  @change="restarHora(diasAtencion.primerTurno.miercoles,
                                  'primerTurno')">
                                </div>
                                <div class="col-sm-3 pl-0">
                                  {{diasAtencion.primerTurno.miercoles.totalTiempo}}
                                </div>
                              </div>
                              <!-- Jueves -->
                              <div class="row mb-1">
                                <div class="col-sm-1 text-right pr-0">
                                  <div class="form-check pt-1">
                                    <input class="form-check-input" type="checkbox"
                                    id="checkJuevesPrimerTurno"
                                    v-model="diasAtencion.primerTurno.jueves.estado">
                                  </div>
                                </div>
                                <div class="col-sm-2">
                                  <label class="col-form-label" for="checkJuevesPrimerTurno">
                                    Jueves:
                                  </label>
                                </div>
                                <div class="col-sm-3 pr-0">
                                <input type="time" class="form-control pl-1 pr-2"
                                  v-model="diasAtencion.primerTurno.jueves.ingreso"
                                  :disabled="!diasAtencion.primerTurno.jueves.estado"
                                  @change="restarHora(diasAtencion.primerTurno.jueves,
                                  'primerTurno')">
                                </div>
                                <div class="col-sm-3 pl-1">
                                  <input type="time" class="form-control pl-1 pr-2"
                                  v-model="diasAtencion.primerTurno.jueves.salida"
                                  :disabled="!diasAtencion.primerTurno.jueves.estado"
                                  @change="restarHora(diasAtencion.primerTurno.jueves,
                                  'primerTurno')">
                                </div>
                                <div class="col-sm-3 pl-0">
                                  {{diasAtencion.primerTurno.jueves.totalTiempo}}
                                </div>
                              </div>
                              <!-- Viernes -->
                              <div class="row mb-1">
                                <div class="col-sm-1 text-right pr-0">
                                  <div class="form-check pt-1">
                                    <input class="form-check-input" type="checkbox"
                                    id="checkViernesPrimerTurno"
                                    v-model="diasAtencion.primerTurno.viernes.estado">
                                  </div>
                                </div>
                                <div class="col-sm-2">
                                  <label class="col-form-label" for="checkViernesPrimerTurno">
                                    Viernes:
                                  </label>
                                </div>
                                <div class="col-sm-3 pr-0">
                                  <input type="time" class="form-control pl-1 pr-2"
                                  v-model="diasAtencion.primerTurno.viernes.ingreso"
                                  :disabled="!diasAtencion.primerTurno.viernes.estado"
                                  @change="restarHora(diasAtencion.primerTurno.viernes,
                                  'primerTurno')">
                                </div>
                                <div class="col-sm-3 pl-1">
                                  <input type="time" class="form-control pl-1 pr-2"
                                  v-model="diasAtencion.primerTurno.viernes.salida"
                                  :disabled="!diasAtencion.primerTurno.viernes.estado"
                                  @change="restarHora(diasAtencion.primerTurno.viernes,
                                  'primerTurno')">
                                </div>
                                <div class="col-sm-3 pl-0">
                                  {{diasAtencion.primerTurno.viernes.totalTiempo}}
                                </div>
                              </div>
                              <!-- Sabado -->
                              <div class="row mb-1">
                                <div class="col-sm-1 text-right pr-0">
                                  <div class="form-check pt-1">
                                    <input class="form-check-input" type="checkbox"
                                    id="checkSabadoPrimerTurno"
                                    v-model="diasAtencion.primerTurno.sabado.estado">
                                  </div>
                                </div>
                                <div class="col-sm-2">
                                  <label class="col-form-label" for="checkSabadoPrimerTurno">
                                    Sabado:
                                  </label>
                                </div>
                                <div class="col-sm-3 pr-0">
                                  <input type="time" class="form-control pl-1 pr-2"
                                  v-model="diasAtencion.primerTurno.sabado.ingreso"
                                  :disabled="!diasAtencion.primerTurno.sabado.estado"
                                  @change="restarHora(diasAtencion.primerTurno.sabado,
                                  'primerTurno')">
                                </div>
                                <div class="col-sm-3 pl-1">
                                  <input type="time" class="form-control pl-1 pr-2"
                                  v-model="diasAtencion.primerTurno.sabado.salida"
                                  :disabled="!diasAtencion.primerTurno.sabado.estado"
                                  @change="restarHora(diasAtencion.primerTurno.sabado,
                                  'primerTurno')">
                                </div>
                                <div class="col-sm-3 pl-0">
                                  {{diasAtencion.primerTurno.sabado.totalTiempo}}
                                </div>
                              </div>
                              <!-- Domingo -->
                              <div class="row mb-3">
                                <div class="col-sm-1 text-right pr-0">
                                  <div class="form-check pt-1">
                                    <input class="form-check-input" type="checkbox"
                                    id="checkDomingoPrimerTurno"
                                    v-model="diasAtencion.primerTurno.domingo.estado">
                                  </div>
                                </div>
                                <div class="col-sm-2">
                                  <label class="col-form-label" for="checkDomingoPrimerTurno">
                                    Domingo:
                                  </label>
                                </div>
                                <div class="col-sm-3 pr-0">
                                  <input type="time" class="form-control pl-1 pr-2"
                                  v-model="diasAtencion.primerTurno.domingo.ingreso"
                                  :disabled="!diasAtencion.primerTurno.domingo.estado"
                                  @change="restarHora(diasAtencion.primerTurno.domingo,
                                  'primerTurno')">
                                </div>
                                <div class="col-sm-3 pl-1">
                                  <input type="time" class="form-control pl-1 pr-2"
                                  v-model="diasAtencion.primerTurno.domingo.salida"
                                  :disabled="!diasAtencion.primerTurno.domingo.estado"
                                  @change="restarHora(diasAtencion.primerTurno.domingo,
                                  'primerTurno')">
                                </div>
                                <div class="col-sm-3 pl-0">
                                  {{diasAtencion.primerTurno.domingo.totalTiempo}}
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <div class="col-sm-12 text-center"><b>Segundo Turno</b></div>
                              <div class="row mb-1">
                                <div class="col-sm-1"></div>
                                <div class="col-sm-2"></div>
                                <div class="col-sm-3 text-center"><b>Ingreso</b></div>
                                <div class="col-sm-3 text-center"><b>Salida</b></div>
                                <div class="col-sm-3"></div>
                              </div>
                              <!-- Lunes -->
                              <div class="row mb-1">
                                <div class="col-sm-1 text-right pr-0">
                                  <div class="form-check pt-1">
                                    <input class="form-check-input" type="checkbox"
                                    id="checkLunesSegundoTurno"
                                    v-model="diasAtencion.segundoTurno.lunes.estado">
                                  </div>
                                </div>
                                <div class="col-sm-2">
                                  <label class="col-form-label" for="checkLunesSegundoTurno">
                                    Lunes:
                                  </label>
                                </div>
                                <div class="col-sm-3 pr-0">
                                <input type="time" class="form-control pl-1 pr-2"
                                  v-model="diasAtencion.segundoTurno.lunes.ingreso"
                                  :disabled="!diasAtencion.segundoTurno.lunes.estado"
                                  @change="restarHora(diasAtencion.segundoTurno.lunes,
                                  'segundoTurno')">
                                </div>
                                <div class="col-sm-3 pl-1">
                                  <input type="time" class="form-control pl-1 pr-2"
                                  v-model="diasAtencion.segundoTurno.lunes.salida"
                                  :disabled="!diasAtencion.segundoTurno.lunes.estado"
                                  @change="restarHora(diasAtencion.segundoTurno.lunes,
                                  'segundoTurno')">
                                </div>
                                <div class="col-sm-3 pl-0">
                                  {{diasAtencion.segundoTurno.lunes.totalTiempo}}
                                </div>
                              </div>
                              <!-- Martes -->
                              <div class="row mb-1">
                                <div class="col-sm-1 text-right pr-0">
                                  <div class="form-check pt-1">
                                    <input class="form-check-input" type="checkbox"
                                    id="checkMartesSegundoTurno"
                                    v-model="diasAtencion.segundoTurno.martes.estado">
                                  </div>
                                </div>
                                <div class="col-sm-2">
                                  <label class="col-form-label" for="checkMartesSegundoTurno">
                                    Martes:
                                  </label>
                                </div>
                                <div class="col-sm-3 pr-0">
                                <input type="time" class="form-control pl-1 pr-2"
                                  v-model="diasAtencion.segundoTurno.martes.ingreso"
                                  :disabled="!diasAtencion.segundoTurno.martes.estado"
                                  @change="restarHora(diasAtencion.segundoTurno.martes,
                                  'segundoTurno')">
                                </div>
                                <div class="col-sm-3 pl-1">
                                  <input type="time" class="form-control pl-1 pr-2"
                                  v-model="diasAtencion.segundoTurno.martes.salida"
                                  :disabled="!diasAtencion.segundoTurno.martes.estado"
                                  @change="restarHora(diasAtencion.segundoTurno.martes,
                                  'segundoTurno')">
                                </div>
                                <div class="col-sm-3 pl-0">
                                  {{diasAtencion.segundoTurno.martes.totalTiempo}}
                                </div>
                              </div>
                              <!-- Miercoles -->
                              <div class="row mb-1">
                                <div class="col-sm-1 text-right pr-0">
                                  <div class="form-check pt-1">
                                    <input class="form-check-input" type="checkbox"
                                    id="checkMiercolesSegundoTurno"
                                    v-model="diasAtencion.segundoTurno.miercoles.estado">
                                  </div>
                                </div>
                                <div class="col-sm-2">
                                  <label class="col-form-label" for="checkMiercolesSegundoTurno">
                                    Miercoles:
                                  </label>
                                </div>
                                <div class="col-sm-3 pr-0">
                                <input type="time" class="form-control pl-1 pr-2"
                                  v-model="diasAtencion.segundoTurno.miercoles.ingreso"
                                  :disabled="!diasAtencion.segundoTurno.miercoles.estado"
                                  @change="restarHora(diasAtencion.segundoTurno.miercoles,
                                  'segundoTurno')">
                                </div>
                                <div class="col-sm-3 pl-1">
                                  <input type="time" class="form-control pl-1 pr-2"
                                  v-model="diasAtencion.segundoTurno.miercoles.salida"
                                  :disabled="!diasAtencion.segundoTurno.miercoles.estado"
                                  @change="restarHora(diasAtencion.segundoTurno.miercoles,
                                  'segundoTurno')">
                                </div>
                                <div class="col-sm-3 pl-0">
                                  {{diasAtencion.segundoTurno.miercoles.totalTiempo}}
                                </div>
                              </div>
                              <!-- Jueves -->
                              <div class="row mb-1">
                                <div class="col-sm-1 text-right pr-0">
                                  <div class="form-check pt-1">
                                    <input class="form-check-input" type="checkbox"
                                    id="checkJuevesSegundoTurno"
                                    v-model="diasAtencion.segundoTurno.jueves.estado">
                                  </div>
                                </div>
                                <div class="col-sm-2">
                                  <label class="col-form-label" for="checkJuevesSegundoTurno">
                                    Jueves:
                                  </label>
                                </div>
                                <div class="col-sm-3 pr-0">
                                <input type="time" class="form-control pl-1 pr-2"
                                  v-model="diasAtencion.segundoTurno.jueves.ingreso"
                                  :disabled="!diasAtencion.segundoTurno.jueves.estado"
                                  @change="restarHora(diasAtencion.segundoTurno.jueves,
                                  'segundoTurno')">
                                </div>
                                <div class="col-sm-3 pl-1">
                                  <input type="time" class="form-control pl-1 pr-2"
                                  v-model="diasAtencion.segundoTurno.jueves.salida"
                                  :disabled="!diasAtencion.segundoTurno.jueves.estado"
                                  @change="restarHora(diasAtencion.segundoTurno.jueves,
                                  'segundoTurno')">
                                </div>
                                <div class="col-sm-3 pl-0">
                                  {{diasAtencion.segundoTurno.jueves.totalTiempo}}
                                </div>
                              </div>
                              <!-- Viernes -->
                              <div class="row mb-1">
                                <div class="col-sm-1 text-right pr-0">
                                  <div class="form-check pt-1">
                                    <input class="form-check-input" type="checkbox"
                                    id="checkViernesSegundoTurno"
                                    v-model="diasAtencion.segundoTurno.viernes.estado">
                                  </div>
                                </div>
                                <div class="col-sm-2">
                                  <label class="col-form-label" for="checkViernesSegundoTurno">
                                    Viernes:
                                  </label>
                                </div>
                                <div class="col-sm-3 pr-0">
                                  <input type="time" class="form-control pl-1 pr-2"
                                  v-model="diasAtencion.segundoTurno.viernes.ingreso"
                                  :disabled="!diasAtencion.segundoTurno.viernes.estado"
                                  @change="restarHora(diasAtencion.segundoTurno.viernes,
                                  'segundoTurno')">
                                </div>
                                <div class="col-sm-3 pl-1">
                                  <input type="time" class="form-control pl-1 pr-2"
                                  v-model="diasAtencion.segundoTurno.viernes.salida"
                                  :disabled="!diasAtencion.segundoTurno.viernes.estado"
                                  @change="restarHora(diasAtencion.segundoTurno.viernes,
                                  'segundoTurno')">
                                </div>
                                <div class="col-sm-3 pl-0">
                                  {{diasAtencion.segundoTurno.viernes.totalTiempo}}
                                </div>
                              </div>
                              <!-- Sabado -->
                              <div class="row mb-1">
                                <div class="col-sm-1 text-right pr-0">
                                  <div class="form-check pt-1">
                                    <input class="form-check-input" type="checkbox"
                                    id="checkSabadoSegundoTurno"
                                    v-model="diasAtencion.segundoTurno.sabado.estado">
                                  </div>
                                </div>
                                <div class="col-sm-2">
                                  <label class="col-form-label" for="checkSabadoSegundoTurno">
                                    Sabado:
                                  </label>
                                </div>
                                <div class="col-sm-3 pr-0">
                                  <input type="time" class="form-control pl-1 pr-2"
                                  v-model="diasAtencion.segundoTurno.sabado.ingreso"
                                  :disabled="!diasAtencion.segundoTurno.sabado.estado"
                                  @change="restarHora(diasAtencion.segundoTurno.sabado,
                                  'segundoTurno')">
                                </div>
                                <div class="col-sm-3 pl-1">
                                  <input type="time" class="form-control pl-1 pr-2"
                                  v-model="diasAtencion.segundoTurno.sabado.salida"
                                  :disabled="!diasAtencion.segundoTurno.sabado.estado"
                                  @change="restarHora(diasAtencion.segundoTurno.sabado,
                                  'segundoTurno')">
                                </div>
                                <div class="col-sm-3 pl-0">
                                  {{diasAtencion.segundoTurno.sabado.totalTiempo}}
                                </div>
                              </div>
                              <!-- Domingo -->
                              <div class="row mb-3">
                                <div class="col-sm-1 text-right pr-0">
                                  <div class="form-check pt-1">
                                    <input class="form-check-input" type="checkbox"
                                    id="checkDomingoSegundoTurno"
                                    v-model="diasAtencion.segundoTurno.domingo.estado">
                                  </div>
                                </div>
                                <div class="col-sm-2">
                                  <label class="col-form-label" for="checkDomingoSegundoTurno">
                                    Domingo:
                                  </label>
                                </div>
                                <div class="col-sm-3 pr-0">
                                  <input type="time" class="form-control pl-1 pr-2"
                                  v-model="diasAtencion.segundoTurno.domingo.ingreso"
                                  :disabled="!diasAtencion.segundoTurno.domingo.estado"
                                  @change="restarHora(diasAtencion.segundoTurno.domingo,
                                  'segundoTurno')">
                                </div>
                                <div class="col-sm-3 pl-1">
                                  <input type="time" class="form-control pl-1 pr-2"
                                  v-model="diasAtencion.segundoTurno.domingo.salida"
                                  :disabled="!diasAtencion.segundoTurno.domingo.estado"
                                  @change="restarHora(diasAtencion.segundoTurno.domingo,
                                  'segundoTurno')">
                                </div>
                                <div class="col-sm-3 pl-0">
                                  {{diasAtencion.segundoTurno.domingo.totalTiempo}}
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- turnos -->
                          <!-- Boton Guardar -->
                          <div class="row">
                            <div class="col-sm-12 text-right">
                              <button
                                class="btn btn-success"
                                @click="$bvModal.hide('modal-atencion')"
                              >
                                Guardar
                              </button>
                            </div>
                          </div>
                          <!-- Fin Boton -->
                        </b-modal>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-3 text-right">
                          <label class="col-form-label">N° Colegio Medico:</label>
                        </div>
                        <div class="col-sm-4">
                          <input type="text" class="form-control"
                          v-model="numeroColegioMedico" autocomplete="off">
                        </div>
                        <div class="col-sm-2 pl-0">
                          <b-form-select v-model="generoSelected" :options="genero"></b-form-select>
                        </div>
                        <label
                          for="foto"
                          class="col-sm-2 btn"
                          :class="{'btn-warning': !isFoto, 'btn-success': isFoto}"
                        >Foto
                        </label>
                        <input type="file" id="foto" @change="perfils" class="file-upload" />
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-3 text-right">
                          <label class="col-form-label">N° Matricula:</label>
                        </div>
                        <div class="col-sm-4">
                          <input type="text" class="form-control"
                          v-model="numeroMatricula" autocomplete="off">
                        </div>
                        <div class="col-sm-2 pl-0">
                          <input type="text" class="form-control" v-model="numeroCarnet"
                          placeholder="C. I." autocomplete="off">
                        </div>
                        <button v-if="imagenPerfil" type="button" class="col-sm-2 btn"
                        v-b-modal.modalfoto>Ver Foto</button>
                        <b-modal size="md" id="modalfoto" title="Foto de Perfil" hide-footer>
                          <img :src="imagenPerfil" alt="Foto Perfil">
                        </b-modal>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-3 text-right">
                          <label class="col-form-label">Celular:</label>
                        </div>
                        <div class="col-sm-2 pt-1">
                          <multiselect2
                            v-model="paisSelected"
                            :options="paises"
                            :allow-empty="false"
                            :show-labels="false"
                            :searchable="false"
                            :close-on-select="true"
                            label="title"
                            track-by="title"
                            @input="setCodigoPais"
                          >
                            <template slot="singleLabel" slot-scope="props">
                              <img class="option__image" :src="props.option.img"
                                :alt="props.option.title" width="26px">
                            </template>
                            <template slot="option" slot-scope="props">
                              <img class="option__image" :src="props.option.img"
                                :alt="props.option.title" width="26px">
                            </template>
                          </multiselect2>
                        </div>
                        <div class="col-sm-4 pl-0 py-1">
                          <div class="input-group input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">{{codigoPais}}</span>
                            </div>
                            <input type="text" class="form-control text-center" v-model="celular"
                            autocomplete="off" @keydown="validarCaracter" @blur="reValidarCelular">
                          </div>
                        </div>
                        <div class="col-sm-2 px-0 text-center">
                          <v-swatches v-model="inputColor"
                          swatch-size="26"
                          :swatches="swatches"
                          shapes="circles">
                          </v-swatches>
                        </div>
                      </div>
                      <div class="row mb-2" v-if="hasPermisionListarConsultaExterna">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <div class="row mb-2 px-3" >
                <div class="col-sm-12 pt-3">
                  <h4><b>Complementos</b></h4>
                </div>
                <div class="col-sm-12 bg-custom-gray rounded py-3">
                  <div class="row mx-5">
                    <button v-if="isDisableTratamiento"
                      class="btn col-sm-2 mr-5"
                      :class="tablaHonorarioMedico.length > 0 ? 'btn-success': 'btn-warning'"
                      @click="openModalHonorario"
                    >
                      Honorario
                    </button>
                    <ModalHonorarioMedico />
                    <template v-if="hasPermisionTurnoMedico">
                      <button
                        class="btn col-sm-2 mr-5"
                        :class="turno1Validate? 'btn-success':'btn-warning'"
                        @click="openModalTurno1"
                      >
                        Turno 1
                      </button>
                      <modalTurno1
                        :tipoTurnoMedicos="tipoTurnoMedicos"
                      />
                      <button
                        class="btn col-sm-2 mr-5"
                        :class="turno2Validate? 'btn-success':'btn-warning'"
                        @click="openModalTurno2"
                      >
                        Turno 2
                      </button>
                      <modalTurno2
                        :tipoTurnoMedicos="tipoTurnoMedicos"
                      />
                    </template>
                      <div class="col-sm-2 mr-5 pl-0" v-if="hasPermisionListarConsultaExterna">
                          <button
                            class="btn-block btn"
                            :class="listaComisiones.length > 0 ? 'btn-success': 'btn-warning'"
                            type="button"
                            @click="openModalComisiones"
                          >
                            Consulta Externa
                          </button>
                          <TheModalConsultaExterna
                          :monedaPrincipal="monedaPrincipal"/>
                        </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="hasPermissionMedicoTercero && activaFacturaElectronica">
                <div class="col-sm-12 pt-3">
                  <h4><b>Facturacion Terceros</b></h4>
                </div>
              </div>
              <div class="row mb-3" v-if="hasPermissionMedicoTercero && activaFacturaElectronica">
                <div class="col-sm-12 bg-custom-green rounded-box py-2">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="row mb-2">
                        <div class="col-sm-3 pr-0 text-right">
                          <label class="col-form-label">Actividad Economica:</label>
                        </div>
                        <div class="col-sm-8">
                          <input type="text" class="form-control"
                          v-model="actividadEconomica" autocomplete="off">
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-3 pr-0 text-right">
                          <label class="col-form-label">Razon Social:</label>
                        </div>
                        <div class="col-sm-8">
                          <input type="text" class="form-control"
                          v-model="razonSocial" autocomplete="off">
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="row mb-2">
                        <div class="col-sm-1 pr-0 text-right">
                          <label class="col-form-label">Ciudad:</label>
                        </div>
                        <div class="col-sm-4">
                          <multiselect2
                            v-model="ciudadSelected"
                            :options="listaCiudades"
                            placeholder=""
                            label="nombre"
                            track-by="id"
                            select-label=""
                            deselect-label="X"
                            :allow-empty="false"
                          >
                            <span slot="noResult">Sin Resultados</span>
                            <span slot="noOptions">Lista Vacia</span>
                          </multiselect2>
                        </div>
                        <div class="col-sm-3 text-right pr-0">
                          <label class="col-form-label">Sucursal:</label>
                        </div>
                        <div class="col-sm-4 pl-1">
                          <b-form-select v-model="sucursalSelected" :options="sucursales">
                          </b-form-select>
                        </div>
                        <!-- <div class="col-sm-4 text-right">
                          <label for="file-upload"
                            class="btn custom-file-upload"
                            :class="{'btn-warning': !isLogo, 'btn-success': isLogo}">Logo</label>
                          <input
                            type="file"
                            id="file-upload"
                            @change="fileUploads" class="file-upload" />
                        </div> -->
                      </div>
                      <div class="row">
                        <div class="col-sm-1 pr-0 text-right">
                          <label class="col-form-label">Nit:</label>
                        </div>
                        <div class="col-sm-4">
                          <input
                            type="text"
                            class="form-control"
                            v-model="nit"
                            @keydown="validarCaracter"
                            @blur="reValidarNit"
                            autocomplete="off"
                          >
                        </div>
                        <div class="col-sm-3 pr-0 text-right">
                          <label class="col-form-label">Celular Factura:</label>
                        </div>
                        <div class="col-sm-4 pl-1">
                          <input
                            type="text"
                            class="form-control"
                            v-model="celularFactura"
                            @keydown="validarCaracter"
                            @blur="revalidarCelularFactura"
                            autocomplete="off"
                          >
                        </div>
                        <div v-if="imagenLogo" class="col-sm-4 text-right">
                          <button type="button" class="btn custom-file-upload"
                          v-b-modal.modallogo>Ver Logo</button>
                          <b-modal size="md" id="modallogo" title="Logo de Factura" hide-footer>
                            <img :src="imagenLogo" alt="Foto Perfil">
                          </b-modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="hasPermissionMedicoTercero && activaFacturaElectronica">
                 <div class="col-sm-12">
                  <div v-if="tieneModalidadElectronica === false" class="row mb-3 py-2 border border-secondary">
                    <div class="col-sm-2">
                      <div class="form-group">
                        <label>N° de Autorización:</label>
                        <input
                          type="text"
                          v-model="numAutorizacion"
                          :readonly="isReadonly"
                          class="form-control"
                          autocomplete="off">
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label>LLave de Dosificación:</label>
                        <input
                          type="text"
                          v-model="llaveDosificacion"
                          :readonly="isReadonly"
                          class="form-control"
                          autocomplete="off">
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="form-group">
                        <label>Leyenda:</label>
                        <input
                          type="text"
                          v-model="leyenda"
                          :readonly="isReadonly"
                          class="form-control"
                          autocomplete="off">
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="form-group">
                        <label>Fecha Venc:</label>
                        <date-picker
                          class="col-sm-10 px-0"
                          v-model="fechaFacturacionTerceros"
                          lang="es"
                          :disabled="isReadonly"
                          format="DD/MM/YYYY"
                          type="date"
                          value-type="format"
                        ></date-picker>
                      </div>
                    </div>
                    <div class="col-sm-1 pl-0">
                      <div class="form-group">
                        <label>Inicio:</label>
                        <input
                          type="text"
                          v-model="inicio"
                          :readonly="isReadonly"
                          class="form-control"
                          autocomplete="off">
                      </div>
                    </div>
                    <div class="col-sm-2 pt-3">
                      <button
                        class="btn btn-warning"
                        :disabled="isReadonly"
                        @click="listTablas"
                      >Agregar</button>
                    </div>
                  </div>
                  <b-table
                    class="table table-hover"
                    :fields="fields"
                    :items="listaTabla"
                    v-if="hasPermissionMedicoTercero && activaFacturaElectronica && tieneModalidadElectronica === false"
                  >
                  <template #cell(editar)="row">
                    <i class="nav-icon i-Pen-5 text-success font-weight-bold"
                      style="cursor: pointer"
                      @click="editItems(row.index)"></i>
                  </template>
                  <template #cell(eliminar)="rows">
                    <i class="nav-icon i-Close-Window text-danger font-weight-bold"
                      :class="{'d-none': isDisabledDelete}"
                      style="cursor: pointer"
                      @click="deleteItems(rows.index)"></i>
                  </template>
                  </b-table>
                <div v-if="tieneModalidadElectronica === true" class="row mb-3 py-2 border border-secondary">
                  <div class="col-sm-2">
                    <div class="form-group">
                      <label>N° de Autorizacion:
                        <span class="text-danger">*</span>
                      </label>
                        <input type="text" class="form-control" v-model="numeroAutorizacionElectronica">
                    </div>
                  </div>
                  <div class="col-sm-2">
                    <div class="form-group">
                      <label>Doc. Sector:
                        <span class="text-danger">*</span>
                      </label>
                        <multiselect2
                        v-model="docSectorSelected"
                        :options="listaDocsSector"
                        :disabled="isDisabledFormFacturaElectronica"
                        placeholder=""
                        label="emisor_descripcion"
                        track-by="id"
                        select-label=""
                        deselect-label="X">
                        <span slot="noResult">Sin Resultados</span>
                        <span slot="noOptions">Lista Vacia</span>
                      </multiselect2>
                    </div>
                  </div>
                    <div class="col-sm-2 pl-0">
                    <div class="form-group">
                      <label>Tipo de Documentos:
                        <span class="text-danger">*</span>
                      </label>
                        <multiselect2
                        v-model="docFacturacionSelected"
                        :options="listaDocsFacturacion"
                        :disabled="isDisabledFormFacturaElectronica"
                        placeholder=""
                        label="emisor_descripcion"
                        track-by="id"
                        select-label=""
                        deselect-label="X">
                        <span slot="noResult">Sin Resultados</span>
                        <span slot="noOptions">Lista Vacia</span>
                      </multiselect2>
                    </div>
                  </div>
                  <div class="col-sm-1 px-0">
                    <div class="form-group">
                      <label>Secuencia:
                        <span class="text-danger">*</span>
                      </label>
                        <multiselect2
                        v-model="secuenciaSelected"
                        :options="listaSecuencias"
                        :disabled="isDisabledFormFacturaElectronica"
                        placeholder=""
                        label="emisor_descripcion"
                        track-by="id"
                        select-label=""
                        deselect-label="X">
                        <span slot="noResult">Sin Resultados</span>
                        <span slot="noOptions">Lista Vacia</span>
                      </multiselect2>
                    </div>
                  </div>
                  <div class="col-sm-2">
                    <div class="form-group">
                      <label>Fecha Venc:
                        <span class="text-danger">*</span>
                      </label>
                      <date-picker
                        class="col-sm-12 px-0"
                        lang="es"
                        format="DD/MM/YYYY"
                        type="date"
                        value-type="format"
                      ></date-picker>
                    </div>
                  </div>
                  <div class="col-sm-1 px-0">
                    <div class="form-group">
                      <label>N° Inicial:
                        <span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        autocomplete="off"
                      >
                    </div>
                  </div>
                  <div class="col-sm-1">
                    <div class="form-group">
                      <label>Tamaño:
                        <span class="text-danger">*</span>
                      </label>
                        <multiselect2
                        v-model="tipoHojaSelected"
                        :options="listaTipoHojas"
                        :disabled="isDisabledFormFacturaElectronica"
                        placeholder=""
                        label="emisor_descripcion"
                        track-by="id"
                        select-label=""
                        deselect-label="X">
                        <span slot="noResult">Sin Resultados</span>
                        <span slot="noOptions">Lista Vacia</span>
                      </multiselect2>
                    </div>
                  </div>
                  <div class="col-sm-1 pt-3">
                    <button
                      class="btn btn-warning btn-block px-0"
                    >Agregar
                    </button>
                  </div>
                  </div>
                  <b-table
                      class="table table-hover"
                      :fields="fieldsFacturaElectronica"
                      :items="itemsFacturaElectronica"
                      v-if="tieneModalidadElectronica === true"
                    >
                      <template #cell(editar)="row">
                        <i
                          class="nav-icon i-Pen-5 text-success font-weight-bold"
                          :class="{'d-none': row.item._rowVariant === 'danger'}"
                          style="cursor: pointer"
                          @click="editItemFacturaElectronica(row.item)"
                        ></i>
                      </template>
                      <template #cell(eliminar)="row">
                        <i
                          class="nav-icon i-Close-Window text-danger font-weight-bold"
                          :class="{'d-none': row.item._rowVariant === 'danger'}"
                          style="cursor: pointer"
                          @click="deleteItemFacturaElectronica(row.index)"
                        ></i>
                      </template>
                  </b-table>
                </div>
              </div>
              <!-- Tabla -->
              <!-- Fin Tabla -->
            </div>
          <!-- </form> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store/index';
import { TIPO_FUNCION_COMISION } from '@/utils/globalConventions';
import { mapActions, mapGetters } from 'vuex';
import Multiselect2 from '@/components/util/vue-multiselect2';
import DatePicker from 'vue2-datepicker';
import VSwatches from 'vue-swatches';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import 'vue-swatches/dist/vue-swatches.css';
import { mapFields } from 'vuex-map-fields';
import ModalHonorarioMedico from '../components/TheModalHonorarioMedico.vue';
import modalTurno1 from '../components/modalTurno1.vue';
import modalTurno2 from '../components/modalTurno2.vue';
import axios from '../../common/axiosERP';
import { util } from '../../../plugins/util';
import TheModalConsultaExterna from '../components/TheModalConsultaExterna.vue';

export default {
  name: 'create',
  components: {
    Multiselect2,
    DatePicker,
    VSwatches,
    ModalHonorarioMedico,
    modalTurno1,
    modalTurno2,
    TheModalConsultaExterna,
  },
  data() {
    return {
      nombre: '',
      email: '',
      direccion: '',
      paisSelected: { title: 'Bo', codigo: '+591', img: 'https://cdn.countryflags.com/thumbs/bolivia/flag-400.png' },
      codigoPais: '+591',
      celular: '',
      paises: [
        { title: 'Bo', codigo: '+591', img: 'https://cdn.countryflags.com/thumbs/bolivia/flag-400.png' },
        { title: 'Br', codigo: '+55', img: 'https://cdn.countryflags.com/thumbs/brazil/flag-400.png' },
        { title: 'Ar', codigo: '+54', img: 'https://cdn.countryflags.com/thumbs/argentina/flag-400.png' },
        { title: 'Es', codigo: '+34', img: 'https://cdn.countryflags.com/thumbs/spain/flag-400.png' },
        { title: 'Pe', codigo: '+51', img: 'https://cdn.countryflags.com/thumbs/peru/flag-400.png' },
        { title: 'Co', codigo: '+57', img: 'https://cdn.countryflags.com/thumbs/colombia/flag-400.png' },
      ],
      inputColor: '#1c5abd',
      fechaNacimiento: new Date(),
      numeroColegioMedico: '',
      numeroMatricula: '',
      numeroCarnet: '',
      actividadEconomica: '',
      razonSocial: '',
      fechaFacturacionTerceros: '',
      edadActual: null,
      especialidadInput: '',
      especialidadesSelected: [],
      generoSelected: 'M',
      ciudadSelected: null,
      nit: '',
      genero: [
        { value: 'H', text: 'Hombre' },
        { value: 'M', text: 'Mujer' },
      ],
      listaCiudades: [],
      especialidades: [],
      especialidad_id: [],
      fields: [
        { key: 'numero', label: 'N°', thStyle: { width: '5%' } },
        {
          key: 'numero_autorizacion', label: 'N° Autorizacion', class: 'text-center', thStyle: { width: '10%' },
        },
        {
          key: 'llave_dosificacion', label: 'LLave de Dosificación', class: 'text-center', thStyle: { width: '30%' },
        },
        {
          key: 'leyenda', label: 'Leyenda', class: 'text-center', thStyle: { width: '17%' },
        },
        {
          key: 'fecha_limite_emision', label: 'Fecha Venc', class: 'text-center', thStyle: { width: '17%' },
        },
        {
          key: 'numero_inicial', label: 'N° Inicial', class: 'text-center', thStyle: { width: '10%' },
        },
        {
          key: 'numero_actual', label: 'N° Actual', class: 'text-center', thStyle: { width: '10%' },
        },
        {
          key: 'editar', label: ' ', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'eliminar', label: ' ', class: 'text-center', thStyle: { width: '3%' },
        },
      ],
      listaTabla: [],
      diasAtencion: {
        primerTurno: {
          lunes: {
            clave: 'lunes',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          martes: {
            clave: 'martes',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          miercoles: {
            clave: 'miercoles',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          jueves: {
            clave: 'jueves',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          viernes: {
            clave: 'viernes',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          sabado: {
            clave: 'sabado',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          domingo: {
            clave: 'domingo',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
        },
        segundoTurno: {
          lunes: {
            clave: 'lunes',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          martes: {
            clave: 'martes',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          miercoles: {
            clave: 'miercoles',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          jueves: {
            clave: 'jueves',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          viernes: {
            clave: 'viernes',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          sabado: {
            clave: 'sabado',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          domingo: {
            clave: 'domingo',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
        },
      },
      swatches: [
        { color: '#1c5abd', disabled: true },
        { color: '#ffdba3', disabled: false },
        { color: '#2ECC70', disabled: true },
        { color: '#1FBC9C', disabled: false },
        { color: '#27AF60', disabled: true },
        { color: '#3398DB', disabled: false },
        { color: '#2980B9', disabled: false },
        { color: '#A463BF', disabled: false },
        { color: '#8E43AD', disabled: false },
        { color: '#3D556E', disabled: false },
        { color: '#222F3D', disabled: false },
        { color: '#F2C511', disabled: true },
      ],
      numAutorizacion: '',
      llaveDosificacion: '',
      leyenda: '',
      inicio: '',
      perfil: null,
      fileUpload: null,
      nextNumber: null,
      mostImg: null,
      mostlogo: null,
      editTableFind: null,
      isAtencion: false,
      isFoto: false,
      isLogo: false,
      isReadonly: false,
      isDisabledDelete: false,
      sucursales: [],
      sucursalSelected: null,
      isDisableTratamiento: store.getters['main/hasPermisionListarTratamiento'],
      celularFactura: '',
      tipoTurnoMedicos: [],
      monedaPrincipal: null,
      load: false,
      fieldsFacturaElectronica: [
        {
          key: 'numero', label: 'N°', thStyle: { width: '5%' },
        },
        {
          key: 'siat_nombre_documento_sector', label: 'Doc. Sector', class: 'text-center', thStyle: { width: '16%' },
        },
        {
          key: 'siat_nombre_tipo_documento', label: 'Tipo de Documentos', class: 'text-center', thStyle: { width: '16%' },
        },
        {
          key: 'configuracion_secuencia_name', label: 'Secuencia', class: 'text-center', thStyle: { width: '15%' },
        },
        {
          key: 'fecha_limite_emision', label: 'Fecha Venc', class: 'text-center', thStyle: { width: '10%' },
        },
        {
          key: 'numero_inicial', label: 'N° Inicial', class: 'text-center', thStyle: { width: '10%' },
        },
        {
          key: 'numero_actual', label: 'N° Actual', class: 'text-center', thStyle: { width: '10%' },
        },
        {
          key: 'configuracio_tipo_hoja_name', label: 'Tamaño', class: 'text-center', thStyle: { width: '12%' },
        },
        {
          key: 'editar', label: ' ', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'eliminar', label: ' ', class: 'text-center', thStyle: { width: '3%' },
        },
      ],
      docSectorSelected: null,
      docFacturacionSelected: null,
      secuenciaSelected: null,
      tipoHojaSelected: null,
      listaDocsSector: [],
      listaDocsFacturacion: [],
      listaSecuencias: [],
      listaTipoHojas: [],
      itemsFacturaElectronica: [],
      tieneModalidadSinFactura: false,
      tieneModalidadFacturaManual: false,
      modalidades: [],
      nombreModalidad: 'Factura en Linea (SIAT)',
      activaFacturaElectronica: false,
      isDisabledFormFacturaElectronica: true,
      tieneModalidadElectronica: false,
      tieneModalidadComputarizada: false,
      tipoFactura: '',
      numeroAutorizacionElectronica: null,
    };
  },
  created() {
    this.cargarDatosIniciales();
    this.getAge(this.fechaNacimiento);
    this.randomColor();
  },
  beforeDestroy() {
    this.resetModalHonorarioMedico();
    this.resetModalTurno1();
    this.resetModalTurno2();
    this.resetModalComisiones();
  },
  methods:
  {
    ...mapActions('modalHonorarioMedico', [
      'resetModalHonorarioMedico',
    ]),
    ...mapActions('modalTurno1', [
      'resetModalTurno1',
    ]),
    ...mapActions('modalTurno2', [
      'resetModalTurno2',
    ]),
    ...mapActions('modalComisiones', [
      'resetModalComisiones',
    ]),
    cargarEspecialidades(lista) {
      const listaAux = lista;
      listaAux.forEach((item, index) => {
        listaAux[index].text = listaAux[index].nombre;
      });
      this.especialidades = listaAux;
    },
    cargarSucursales(payload) {
      payload.forEach((item) => {
        this.sucursales.push({
          value: item.id,
          text: item.nombre,
        });
      });
    },
    async cargarDatosIniciales() {
      try {
        const response = await axios.get('/clinic/doctors/medico/create');
        const { especialidades } = response.data.data;
        const { sucursales } = response.data.data;
        const TIPO_SERVICIOS = response.data.data.tipo_servicios;
        const TIPO_FACTURAS = response.data.data.funciones;
        TIPO_FACTURAS.forEach((el, index) => {
          // desabilidar la opcion preRetencion(emp_asume) y interno(no hace ld)
          this.$set(TIPO_FACTURAS[index], '$isDisabled', false);
          if (el.id === TIPO_FUNCION_COMISION.INTERNO
            || el.id === TIPO_FUNCION_COMISION.PRE_RENTECION_EM
          ) {
            this.$set(TIPO_FACTURAS[index], '$isDisabled', true);
          }
        });
        const TIPO_COMISIONS = response.data.data.comisiones;
        const CATEGORIA_MEDICO = response.data.data.categoria_medico;
        const PISO_CONSULTORIO = response.data.data.piso_consultorio;
        const { ciudades } = response.data.data;
        const CATEGORIA_SERVICIOS = response.data.data.categoria_servicios;

        this.cargarEspecialidades(especialidades);
        this.cargarSucursales(sucursales);
        this.listaServicios = TIPO_SERVICIOS;
        this.tipoFacturas = TIPO_FACTURAS;
        this.tipoComisions = TIPO_COMISIONS;
        this.listaCategorias = CATEGORIA_MEDICO;
        this.listaConsultorio1 = PISO_CONSULTORIO;
        this.listaConsultorio2 = PISO_CONSULTORIO;
        this.listaCiudades = ciudades;
        this.categoriaServicios = CATEGORIA_SERVICIOS;
        this.tipoTurnoMedicos = response.data.data.tipo_turno_medicos;
        this.monedaPrincipal = response.data.data.moneda_principal;
        this.modalidades = response.data.data.modalidad_factura_medico;
        // add la modalidad acticar Factura Electronica, se add con id null ya que este registro
        // no existe en los registros de  modalidad que llega de la BD
        const MODALIDAD_ACTIVAR_FACTURA = {
          id: '',
          nombre: 'Activar Factura Electronica',
        };
        this.modalidades.unshift(MODALIDAD_ACTIVAR_FACTURA);
        this.load = true;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async persistirDatos(request) {
      try {
        const response = await axios.post('/clinic/doctors/medico', request);
        util.showNotify(response.data.message, 'success');
        this.cleanInput();
        this.$router.push({ name: 'medico-index' });
      } catch (error) {
        const FALLO_VALIDACION = 422;
        if (error.response.status !== FALLO_VALIDACION) {
          util.showNotify(error.response.data.message, 'error');
        } else {
          Object.entries(error.response.data.data).forEach(([, mensajes]) => {
            mensajes.forEach((texto) => util.showNotify(texto, 'error'));
          });
        }
      }
    },
    guardarMedico() {
      const form = new FormData();
      form.append('nombre', this.nombre !== null ? this.nombre : '');
      form.append('sexo', this.generoSelected !== null ? this.generoSelected : '');
      form.append('email', this.email !== null ? this.email : '');
      form.append('direccion', this.direccion !== null ? this.direccion : '');
      form.append('fecha_nacimiento', this.fullformateDate(this.fechaNacimiento));
      form.append('carnet_identidad', this.numeroCarnet !== null ? this.numeroCarnet : '');
      form.append('telefono', this.celular !== null ? this.celular : '');
      form.append('telefono_codigo', this.codigoPais !== null ? this.codigoPais : '');
      form.append('color', this.inputColor);
      form.append('numero_colegio_medico', this.numeroColegioMedico !== null ? this.numeroColegioMedico : '');
      form.append('numero_matricula', this.numeroMatricula !== null ? this.numeroMatricula : '');
      form.append('configuracion_factura_tercero', this.tieneModalidadComputarizada); // factura_tercero(1 comp, 0 electronica)
      form.append('factura_manual', this.tieneModalidadFacturaManual);
      form.append('sin_factura', this.tieneModalidadSinFactura);
      form.append('tipo_factura', this.tipoFactura);

      const MODAL_TURNOS = [];
      if (this.getDataModalTurno1 !== null) {
        MODAL_TURNOS.push(this.getDataModalTurno1);
      }
      if (this.getDataModalTurno2 !== null) {
        MODAL_TURNOS.push(this.getDataModalTurno2);
      }
      if (MODAL_TURNOS.length === 0) {
        form.append('turno_medico[]', '');
      }
      for (let index = 0; index < MODAL_TURNOS.length; index += 1) {
        form.append(`turno_medico[${index}][numero_turno]`, MODAL_TURNOS[index].numero_turno);
        form.append(`turno_medico[${index}][ti_lunes]`, MODAL_TURNOS[index].lunesIngreso);
        form.append(`turno_medico[${index}][ti_martes]`, MODAL_TURNOS[index].martesIngreso);
        form.append(`turno_medico[${index}][ti_miercoles]`, MODAL_TURNOS[index].miercolesIngreso);
        form.append(`turno_medico[${index}][ti_jueves]`, MODAL_TURNOS[index].juevesIngreso);
        form.append(`turno_medico[${index}][ti_viernes]`, MODAL_TURNOS[index].viernesIngreso);
        form.append(`turno_medico[${index}][ti_sabado]`, MODAL_TURNOS[index].sabadoIngreso);
        form.append(`turno_medico[${index}][ti_domingo]`, MODAL_TURNOS[index].domingoIngreso);
        form.append(`turno_medico[${index}][ts_lunes]`, MODAL_TURNOS[index].lunesSalida);
        form.append(`turno_medico[${index}][ts_martes]`, MODAL_TURNOS[index].martesSalida);
        form.append(`turno_medico[${index}][ts_miercoles]`, MODAL_TURNOS[index].miercolesSalida);
        form.append(`turno_medico[${index}][ts_jueves]`, MODAL_TURNOS[index].juevesSalida);
        form.append(`turno_medico[${index}][ts_viernes]`, MODAL_TURNOS[index].viernesSalida);
        form.append(`turno_medico[${index}][ts_sabado]`, MODAL_TURNOS[index].sabadoSalida);
        form.append(`turno_medico[${index}][ts_domingo]`, MODAL_TURNOS[index].domingoSalida);
        form.append(`turno_medico[${index}][paciente_por_dia_lunes]`, MODAL_TURNOS[index].lunesPacientes);
        form.append(`turno_medico[${index}][paciente_por_dia_martes]`, MODAL_TURNOS[index].martesPacientes);
        form.append(`turno_medico[${index}][paciente_por_dia_miercoles]`, MODAL_TURNOS[index].miercolesPacientes);
        form.append(`turno_medico[${index}][paciente_por_dia_jueves]`, MODAL_TURNOS[index].juevesPacientes);
        form.append(`turno_medico[${index}][paciente_por_dia_viernes]`, MODAL_TURNOS[index].viernesPacientes);
        form.append(`turno_medico[${index}][paciente_por_dia_sabado]`, MODAL_TURNOS[index].sabadoPacientes);
        form.append(`turno_medico[${index}][paciente_por_dia_domingo]`, MODAL_TURNOS[index].domingoPacientes);
        form.append(`turno_medico[${index}][tiempo_atencion_lunes]`, MODAL_TURNOS[index].lunesTiempoAtencion);
        form.append(`turno_medico[${index}][tiempo_atencion_martes]`, MODAL_TURNOS[index].martesTiempoAtencion);
        form.append(`turno_medico[${index}][tiempo_atencion_miercoles]`, MODAL_TURNOS[index].miercolesTiempoAtencion);
        form.append(`turno_medico[${index}][tiempo_atencion_jueves]`, MODAL_TURNOS[index].juevesTiempoAtencion);
        form.append(`turno_medico[${index}][tiempo_atencion_viernes]`, MODAL_TURNOS[index].viernesTiempoAtencion);
        form.append(`turno_medico[${index}][tiempo_atencion_sabado]`, MODAL_TURNOS[index].sabadoTiempoAtencion);
        form.append(`turno_medico[${index}][tiempo_atencion_domingo]`, MODAL_TURNOS[index].domingoTiempoAtencion);
        form.append(`turno_medico[${index}][piso_consultorio_id_lunes]`, MODAL_TURNOS[index].lunesConsultorioSelected?.id ?? '');
        form.append(`turno_medico[${index}][piso_consultorio_id_martes]`, MODAL_TURNOS[index].martesConsultorioSelected?.id ?? '');
        form.append(`turno_medico[${index}][piso_consultorio_id_miercoles]`, MODAL_TURNOS[index].miercolesConsultorioSelected?.id ?? '');
        form.append(`turno_medico[${index}][piso_consultorio_id_jueves]`, MODAL_TURNOS[index].juevesConsultorioSelected?.id ?? '');
        form.append(`turno_medico[${index}][piso_consultorio_id_viernes]`, MODAL_TURNOS[index].viernesConsultorioSelected?.id ?? '');
        form.append(`turno_medico[${index}][piso_consultorio_id_sabado]`, MODAL_TURNOS[index].sabadoConsultorioSelected?.id ?? '');
        form.append(`turno_medico[${index}][piso_consultorio_id_domingo]`, MODAL_TURNOS[index].domingoConsultorioSelected?.id ?? '');
        form.append(`turno_medico[${index}][tipo_turno_medico_id]`, MODAL_TURNOS[index].tipoTurnoSelected);
        form.append(`turno_medico[${index}][tiempo_descanso]`, MODAL_TURNOS[index].tiempoDescanso);
      }
      if (this.tablaHonorarioMedico.length === 0) {
        form.append('medico_honorarios[]', '');
      }
      for (let index = 0; index < this.tablaHonorarioMedico.length; index += 1) {
        form.append(`medico_honorarios[${index}][tipo_servicio_id]`, this.tablaHonorarioMedico[index].tipo_servicio_id);
        form.append(`medico_honorarios[${index}][categoria_medico_id]`, this.tablaHonorarioMedico[index].categoria_medico_id);
        form.append(`medico_honorarios[${index}][precio_costo]`, this.tablaHonorarioMedico[index].precio_costo);
        form.append(`medico_honorarios[${index}][precio_paciente]`, this.tablaHonorarioMedico[index].precio_paciente);
      }

      form.append('actividad_economica', this.actividadEconomica !== null ? this.actividadEconomica : '');
      form.append('razon_social', this.razonSocial !== null ? this.razonSocial : '');
      form.append('nit', this.nit !== null ? this.nit : '');
      form.append('ciudad_id', this.ciudadSelected !== null ? this.ciudadSelected.id : '');
      form.append('sucursal_id', this.sucursalSelected !== null ? this.sucursalSelected : '');
      form.append('celular_factura', this.celularFactura !== null ? this.celularFactura : '');

      if (this.fileUpload !== null) {
        form.append('logo', this.fileUpload);
      }
      if (this.perfil !== null) {
        form.append('perfil', this.perfil);
      }

      if (this.listaComisiones.length === 0) {
        form.append('consulta_externa_servicios[]', '');
      }
      for (let index = 0; index < this.listaComisiones.length; index += 1) {
        form.append(`consulta_externa_servicios[${index}][funcion_id]`, this.listaComisiones[index].funcion_id);
        form.append(`consulta_externa_servicios[${index}][comision_id]`, this.listaComisiones[index].comision_id);
        form.append(`consulta_externa_servicios[${index}][comision_simbolo]`, this.listaComisiones[index].comision_simbolo);
        form.append(`consulta_externa_servicios[${index}][reconsulta]`, this.listaComisiones[index].reconsulta);
        form.append(`consulta_externa_servicios[${index}][tiempo_validez]`, this.listaComisiones[index].tiempo_validez);
        if (this.listaComisiones[index].tratante === null) {
          this.listaComisiones[index].tratante = 0;
        }
        form.append(`consulta_externa_servicios[${index}][tratante]`, this.listaComisiones[index].tratante);
        if (this.listaComisiones[index].referido === null) {
          this.listaComisiones[index].referido = 0;
        }
        form.append(`consulta_externa_servicios[${index}][referido]`, this.listaComisiones[index].referido);
        form.append(`consulta_externa_servicios[${index}][tipo_servicio_id]`, this.listaComisiones[index].tipo_servicio_id);
      }
      const data = {
        especialidad_id: this.especialidad_id,
        diasAtencion: this.diasAtencion,
        factura: this.listaTabla,
      };
      this.serializeAll(form, data);
      this.persistirDatos(form);
    },
    cleanInput() {
      this.nombre = '';
      this.generoSelected = '';
      this.email = '';
      this.direccion = '';
      this.fechaNacimiento = '';
      this.numeroCarnet = '';
      this.celular = '';
      this.codigoPais = '';
      this.inputColor = '';
      this.numeroColegioMedico = '';
      this.numeroMatricula = '';
      this.actividadEconomica = '';
      this.razonSocial = '';
      this.nit = '';
      this.ciudad_id = '';
      this.especialidad_id = [];
      this.listaTabla = [];
      this.diasAtencion = {
        primerTurno: {
          lunes: {
            clave: 'lunes',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          martes: {
            clave: 'martes',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          miercoles: {
            clave: 'miercoles',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          jueves: {
            clave: 'jueves',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          viernes: {
            clave: 'viernes',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          sabado: {
            clave: 'sabado',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          domingo: {
            clave: 'domingo',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
        },
        segundoTurno: {
          lunes: {
            clave: 'lunes',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          martes: {
            clave: 'martes',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          miercoles: {
            clave: 'miercoles',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          jueves: {
            clave: 'jueves',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          viernes: {
            clave: 'viernes',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          sabado: {
            clave: 'sabado',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          domingo: {
            clave: 'domingo',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
        },
      };
      this.celularFactura = '';
    },
    serializeAll(form, data) {
      const datas = data;
      Object.entries(datas).forEach((items) => {
        if (Array.isArray(items[1]) && items[0] === 'especialidad_id') {
          Object.entries(items[1]).forEach((d, index) => {
            form.append(`especialidad_id[${[index]}]`, d[1]);
          });
        } else if (Array.isArray(items[1]) && items[0] === 'factura') {
          Object.entries(items[1]).forEach((aux, k) => {
            Object.entries(aux[1]).forEach((m) => {
              if (m[0] === 'fecha_limite_emision') {
                form.append(`factura[${k}][${m[0]}]`, this.formatearDate(m[1]));
              } else {
                form.append(`factura[${k}][${m[0]}]`, m[1]);
              }
            });
          });
        } else if (typeof items[1] === 'object' && items[0] === 'diasAtencion') {
          Object.entries(datas.diasAtencion).forEach((d) => {
            Object.entries(d[1]).forEach((t) => {
              if (t[1].ingreso !== '') {
                form.append(`${d[0] + t[1].clave}Ingreso`, t[1].ingreso);
              }
              if (t[1].salida !== '') {
                form.append(`${d[0] + t[1].clave}Salida`, t[1].salida);
              }
            });
          });
        }
      });
    },
    setCodigoPais() {
      this.codigoPais = this.paisSelected.codigo;
    },
    getAge(dateString) {
      const today = new Date();
      const birthDate = new Date(dateString);
      let age = parseInt(today.getFullYear(), 10) - parseInt(birthDate.getFullYear(), 10);
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age -= 1;
      }
      this.edadActual = `${age} Años`;
    },
    validarCaracter(event) {
      const validKeys = new RegExp('^[0-9]*$');
      const controlKeys = ['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight', 'KeyX', 'KeyC', 'KeyV', 'Home', 'End', 'Tab'];
      if (controlKeys.includes(event.code)) {
        switch (event.code) {
          case 'KeyX':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          case 'KeyC':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          case 'KeyV':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          default:
            break;
        }
        return;
      }
      if (!validKeys.test(event.key)) {
        event.preventDefault();
      }
    },
    reValidarCelular() {
      if (this.celular === '') {
        return;
      }
      const validKeys = new RegExp('^[0-9]*$');
      if (!validKeys.test(this.celular)) {
        this.celular = '';
      }
    },
    revalidarCelularFactura() {
      if (this.celularFactura === '') {
        return;
      }
      const validKeys = new RegExp('^[0-9]*$');
      if (!validKeys.test(this.celularFactura)) {
        this.celularFactura = '';
      }
    },
    reValidarNit() {
      if (this.nit === '') {
        return;
      }
      const validKeys = new RegExp('^[0-9.,]*$');
      if (!validKeys.test(this.nit)) {
        this.nit = '';
      }
    },
    randomColor() {
      let randomColor = Math.floor(Math.random() * 16777215).toString(16);
      if (randomColor.length < 5) {
        randomColor += '0';
      }
      this.inputColor = `#${randomColor}`;
    },
    restarHora(dia, turno) {
      if (dia.ingreso === '' || dia.salida === '') {
        this.diasAtencion[turno][dia.clave].totalTiempo = '';
        this.isAtencion = false;
        return;
      }
      // Calcula los minutos de cada hora
      const minutosInicio = dia.ingreso.split(':')
        .reduce((p, c) => parseInt(p, 10) * 60 + parseInt(c, 10));
      const minutosFinal = dia.salida.split(':')
        .reduce((p, c) => parseInt(p, 10) * 60 + parseInt(c, 10));
      // Si la hora final es anterior a la hora inicial sale
      if (minutosFinal < minutosInicio) {
        this.diasAtencion[turno][dia.clave].totalTiempo = 'No puede ser Ingreso Menor que Salida';
        this.isAtencion = false;
        return;
      }
      // Diferencia de minutos
      const diferencia = minutosFinal - minutosInicio;
      // Cálculo de horas y minutos de la diferencia
      const horas = Math.floor(diferencia / 60);
      const minutos = diferencia % 60;
      this.isAtencion = true;
      this.diasAtencion[turno][dia.clave].totalTiempo = `${horas} hora y ${minutos} minutos`;
    },
    validarEspecialidad(newEs) {
      if (newEs.length !== 0) {
        const item = [...newEs].pop();
        const longitud = this.especialidades.filter((obj) => obj.text === item.text).length;
        if (longitud !== 0) {
          this.especialidad_id = newEs.map((s) => s.id);
          this.especialidadesSelected = newEs;
        } else {
          newEs.pop();
          this.especialidad_id = newEs.map((s) => s.id);
        }
      } else {
        this.especialidad_id = [];
        newEs.pop();
      }
    },
    formatearDate(fecha) {
      return fecha.split('/').reverse().join('-');
    },
    fullformateDate(fecha) {
      const day = fecha.getDate();
      const month = fecha.getMonth() + 1;
      const year = fecha.getFullYear();
      return `${year}-${month}-${day}`;
    },
    listTablas() {
      if (this.editTableFind === null) {
        this.listaTabla.push({
          numero: this.listaTabla.length + 1,
          numero_autorizacion: this.numAutorizacion,
          llave_dosificacion: this.llaveDosificacion,
          leyenda: this.leyenda,
          fecha_limite_emision: this.fechaFacturacionTerceros,
          numero_inicial: this.inicio,
          numero_actual: 'S/M',
          _rowVariant: 'success',
        });
      } else {
        this.listaTabla[this.editTableFind].numero = this.editTableFind + 1;
        this.listaTabla[this.editTableFind].numero_autorizacion = this.numAutorizacion;
        this.listaTabla[this.editTableFind].llave_dosificacion = this.llaveDosificacion;
        this.listaTabla[this.editTableFind].leyenda = this.leyenda;
        this.listaTabla[this.editTableFind].fecha_limite_emision = this.fechaFacturacionTerceros;
        this.listaTabla[this.editTableFind].numero_inicial = this.inicio;
        this.listaTabla[this.editTableFind].numero_actual = 'S/M';
        // eslint-disable-next-line no-underscore-dangle
        this.listaTabla[this.editTableFind]._rowVariant = 'success';
        this.editTableFind = null;
      }
      this.numAutorizacion = '';
      this.llaveDosificacion = '';
      this.leyenda = '';
      this.fechaFacturacionTerceros = '';
      this.inicio = '';
      this.isReadonly = true;
      this.isDisabledDelete = false;
    },
    editItems(index) {
      this.numAutorizacion = this.listaTabla[index].numero_autorizacion;
      this.llaveDosificacion = this.listaTabla[index].llave_dosificacion;
      this.leyenda = this.listaTabla[index].leyenda;
      this.fechaFacturacionTerceros = this.listaTabla[index].fecha_limite_emision;
      this.inicio = this.listaTabla[index].numero_inicial;
      this.editTableFind = index;
      this.isReadonly = false;
      this.isDisabledDelete = true;
    },
    deleteItems(index) {
      this.listaTabla.splice(index, 1);
      this.isReadonly = false;
      this.isDisabledDelete = false;
      // eslint-disable-next-line no-underscore-dangle
    },
    perfils(e) {
      const file = e.target.files[0];
      this.perfil = file;
      const render = new FileReader();
      render.onload = (s) => {
        this.mostImg = s.target.result;
        this.isFoto = true;
      };
      render.readAsDataURL(file);
    },
    fileUploads(e) {
      const file = e.target.files[0];
      this.fileUpload = file;
      const render = new FileReader();
      render.onload = (s) => {
        this.mostlogo = s.target.result;
        this.isLogo = true;
      };
      render.readAsDataURL(file);
    },
    openModalHonorario() {
      this.$bvModal.show('modal-honorarioMedico');
    },
    openModalTurno1() {
      this.$bvModal.show('modal-turno1');
    },
    openModalTurno2() {
      this.$bvModal.show('modal-turno2');
    },
    cambiarModalidad(nextIndex) {
      if (nextIndex < this.modalidades.length) {
        this.tipoFactura = this.modalidades[nextIndex].id;
      } else {
        this.tipoFactura = this.modalidades[0].id;
      }
      switch (nextIndex) {
        case 1:
          this.tieneModalidadElectronica = false;
          this.tieneModalidadComputarizada = true;
          this.activaFacturaElectronica = true;
          break;
        case 2:
          this.tieneModalidadElectronica = true;
          this.tieneModalidadComputarizada = false;
          this.activaFacturaElectronica = true;
          break;
        case 3:
          this.tieneModalidadElectronica = false;
          this.tieneModalidadComputarizada = false;
          this.activaFacturaElectronica = false;
          break;
        default:
          break;
      }
    },
    colorBtnModalidadElectronica() {
      let color = 'btn-secondary';
      if (this.tieneModalidadComputarizada) {
        color = 'btn-success';
      }
      if (this.tieneModalidadElectronica) {
        color = 'btn-electronica_computarizada';
      }
      return color;
    },
    openModalComisiones() {
      this.$bvModal.show('modal-comisiones');
    },
  },
  computed: {
    ...mapFields('modalHonorarioMedico', [
      'tablaHonorarioMedico',
      'listaServicios',
      'listaCategorias',
    ]),
    ...mapFields('modalComisiones', [
      'categoriaServicios',
      'listaComisiones',
      'tipoFacturas',
      'tipoComisions',
    ]),
    ...mapFields('modalTurno1', {
      listaConsultorio1: 'listaConsultorio',
    }),
    ...mapGetters('modalTurno1', [
      'turno1Validate',
      'getDataModalTurno1',
    ]),
    ...mapFields('modalTurno2', {
      listaConsultorio2: 'listaConsultorio',
    }),
    ...mapGetters('modalTurno2', [
      'turno2Validate',
      'getDataModalTurno2',
    ]),
    ...mapGetters('main', [
      'hasPermissionMedicoTercero',
      'hasPermisionListarConsultaExterna',
      'hasPermisionTurnoMedico',
    ]),
    filteredItems() {
      return this.especialidades.filter((i) => (
        i.text.toLowerCase().indexOf(this.especialidadInput.toLowerCase()) !== -1));
    },
    imagenPerfil() {
      return this.mostImg;
    },
    imagenLogo() {
      return this.mostlogo;
    },
  },
};
</script>

<style scoped>
.bg-custom-gray {
  background: rgba(0, 0, 0, 0.1);
}
.btn-skyblue
{
  color: #fff;
  background-color: #61c6f2;
}
.bg-skyblue
{
  background: #61c6f2 !important;
}
.bg-gray-custom {
  background: #eeeeee;
}
.rounded-box
{
  border-radius: 15px 15px;
}
.file-upload{
  display: none;
}
.custom-file-upload {
  margin-bottom: 0;
}
.btn-electronica_computarizada {
  background-color: #00a8bc;
  color: white;
}
</style>
